.quantity-input {
  height: 30px;

  .field.has-addons {
    display: flex;
    border: 1px solid #cfc6b9;
    justify-content: flex-start;
    margin-bottom: 0;
    flex-shrink: 1;
    flex-grow: 1;
    background: #faf9f7;
    height: 30px;
    border-radius: 5px;
  }

  .icon-button {
    img {
      width: 12px;
      height: 12px;
    }
  }

  .control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
    width: 40px;

    &-input {
      width: 60px;

      input {
        width: 100%;
      }
    }
  }

  .field.has-addons .control:first-child:not(:only-child) .button,
  .field.has-addons .control:first-child:not(:only-child) .input,
  .field.has-addons .control:first-child:not(:only-child) .select select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .button {
    &:focus,
    &:hover {
      box-shadow: none;
      border-color: #dbdbdb;
    }

    &.left {
      border-right: 0;
    }

    &.right {
      border-left: 0;
    }
  }

  .input {
    border-right: 0;
    border-left: 0;
    box-shadow: none;
    height: 30px;
    padding-right: 0 !important;
    padding-left: 0 !important;
    text-align: center;
    width: 2em;

    &:focus,
    &:hover {
      box-shadow: none;
      border-color: #dbdbdb;
    }

    &[type='number'] {
      appearance: textfield;
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
    }
  }

  &.is-horizontal {
    align-items: center;

    .button,
    .input {
      height: 30px;
    }

    .button {
      padding: 5px 12px;
    }
  }

  &.is-cta {
    .button,
    .input {
      line-height: 20px;
      padding: 0 12px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      margin: auto;
    }
  }

  &.is-fullwidth {
    .control-input {
      width: calc(100% - 60px);

      .input {
        width: 100%;
      }
    }
  }

  &.is-fullwidth-mobile {
    @media screen and (max-width: 768px) {
      .control-input {
        width: calc(100% - 60px);

        .input {
          width: 100%;
        }
      }
    }
  }
}

body .ant-divider {
  border-color: #b3b3b3;
  margin: 0;

  &.header {
    margin: 10px 0 20px;

    @media (min-width: 768px) {
      margin: 10px 0 30px;
    }
  }
}

.article {
  background: #fff;
  border: 2px solid #f2ede6;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px !important;
  margin-bottom: 12px !important;

  @media (min-width: 768px) {
    padding: 20px !important;
  }

  span:last-child {
    flex-grow: 1;
  }
}

body .ant-picker {
  border: none;
  background-color: #fff;
  padding: 8px 11px;
  border-radius: 3px;

  .ant-picker-suffix {
    text-align: center;
    align-items: center;
    display: flex;
  }
}

.text-link {
  color: #1d3994;
  text-decoration: underline;
  text-decoration-color: #1d3994;
}
