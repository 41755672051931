@import '~antd/dist/antd.css';
@import './variables';
@import './reset';
@import './font';
@import './sticky';
@import './other';

ul,
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

h1,
h2,
.text-header {
  font-weight: 700;
}
