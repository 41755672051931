.sticky-top {
  position: sticky;
  top: 0;
  z-index: 100;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 100;
}
