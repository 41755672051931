@tailwind base;
@layer base {
}

@tailwind components;
@tailwind utilities;

.ant-modal-xl > .ant-modal {
  width: calc(100vw - 40px) !important;
}

@media (min-width: 768px) {
  .ant-modal-xl > .ant-modal {
    width: calc(100vw - 320px) !important;
  }
}
.ant-spin-dot-item {
  background-color: #00c4c4 !important;
}

h1,
.text-header {
  @apply font-bold text-lg md:text-2xl;
}

h2,
.h2 {
  @apply font-bold text-base md:text-lg;
}

h3,
.h3 {
  @apply font-medium md:text-base text-sm;
}

h4,
.text-content {
  @apply md:text-sm text-[13px] leading-[170%];
}

h5,
.note {
  @apply md:text-xs text-[11px];
}

.text-error {
  @apply text-[#EF3F5E];
}

.s-picker {
  border: 1px solid #b3b3b3 !important;
  border-radius: 3px;
  width: 100%;
}
.s-picker.ant-picker:hover,
.s-picker.ant-picker-focused {
  border: 2px solid #b3b3b3;
  box-shadow: none;
  border-right-width: 3px !important;
}

@media (max-width: 768px) {
  .ant-modal {
    max-width: calc(100vw - 40px) !important;
    /* margin: 46px auto; */
  }
}

.absolute-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
}

.absolute-x-center {
  @apply absolute left-1/2 -translate-x-1/2;
}

svg {
  display: inline;
}

/* .ant-modal-body {
  padding: 0 !important;
  border-radius: 5px !important;
} */

.box-shadow {
  box-shadow: 0px 1px 3px rgba(51, 51, 51, 0.15);
}

.icon-gray-color > svg > path {
  fill: #999999;
}

.ant-picker {
  border: 1px solid #d9d9d9 !important;
}

.ant-table-thead > tr > th {
  background-color: #c3c3c3 !important;
  color: #5d5d5d !important;
}

.ant-radio-wrapper {
  color: #5d5d5d !important;
  font-size: 16px !important;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #00c4c4 !important;
}
.ant-radio-inner::after {
  background-color: #00c4c4 !important;
}

.ant-checkbox-wrapper {
  font-size: 16px !important;
  color: #5d5d5d !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00c4c4 !important;
  border-color: #00c4c4 !important;
}

.ant-table.ant-table-bordered > .ant-table-title {
  border: #7b7b7b !important;
  background-color: #7b7b7b !important;
}

.ant-table-tbody > tr > td {
  color: #5d5d5d;
}

.ant-table-column-sorter {
  color: #5d5d5d !important;
}

th.ant-table-cell.emphasize {
  color: var(--white) !important;
  background: var(--primary-color) !important;
}

th.ant-table-cell.small-emphasize {
  color: var(--white) !important;
  background: var(--secondary-color) !important;
}
