body {
  overflow-x: hidden;
}

::-webkit-scrollbar {
  background-color: rgb(255 255 255 / 10%);
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
  background-color: rgb(200 200 200 / 10%);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(200 200 200 / 30%);
  border-radius: 6px;
  border: 2px solid rgb(200 200 200 / 10%);
}

::-webkit-scrollbar-track:hover {
  background-color: rgb(200 200 200 / 20%);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(200 200 200 / 50%);
}

#nprogress {
  .bar {
    border-bottom: 2px solid var(--primary);
    background: #00c4c4 !important;
  }

  .peg {
    box-shadow: 0 0 10px #f5f5f5, 0 0 5px #f5f5f5;
  }

  .spinner-icon {
    border-top-color: #f5f5f5;
    border-left-color: #f5f5f5;
  }
}

.ant-layout {
  background-color: #fff !important;
}

.link {
  transition: all 300 ease-in-out;

  &:hover {
    color: #f19ca6;
  }
}

body a {
  color: #333;
}

.ant-message .anticon {
  display: inline-flex;
}

.error {
  font-size: 0.75rem;
  color: #ff4d4f;
}
